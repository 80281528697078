import React, { FC, useCallback, useEffect, useState } from 'react';
import { moneyMaskNumber } from 'cross-cutting/masks/money';
import { Close } from '@material-ui/icons';
import { Icon } from '@material-ui/core';

import { useCatalog } from 'context/catalog/CatalogContext';
import styles from './ProductDetail.module.scss';
import useProductDetail from './UseProductDetail';
import ProductCompositions from 'components/productComposition/ProductCompositions';

export interface IProductProps {
    productId: string;
}

const ProductDetail: FC<IProductProps> = ({ productId }) => {
    const {
        product,
        compositions,
        quantity,
        onClickAddQuantityHandle,
        onClickRemoveQuantityHandle,
        onClickAddProduct,
        onChangeCompositionHandle,
        onClickBack,
        handleChangeObservation
    } = useProductDetail({ productId });

    const { catalog } = useCatalog()

    const [hasImageError, setHasImageError] = useState(false);
    const [opacityHeader, setOpacityHeader] = useState(1);
    const [disabledAddProduct, setDisabledAddProduct] = useState(true);

    useEffect(() => {

        return () => {
            window.scrollTo({ top: document.documentElement.scrollTop - 1 });
        }
    }, [])

    const onScroll = useCallback(
        () => {
            const opacity = (200 - window.pageYOffset) / 200;
            if (opacity > 0) {
                setOpacityHeader(opacity);
            } else {
                setOpacityHeader(0);
            }
        },
        [],
    )

    useEffect(() => {
        onScroll();
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll)
        }
    }, [onScroll]);

    useEffect(() => {
        if (!!compositions?.unvalidatedCompositions.length) {
            setDisabledAddProduct(true)
        } else {
            setDisabledAddProduct(false)
        }
        return () => {

        }
    }, [compositions])

    return (
        product ?
            <div id={styles.ProductDetail} >
                <div className={styles.container}>
                    <button type='button' className={styles.backButton} onClick={onClickBack}>
                        <Close className={styles.backButtonIcon} />
                    </button>

                    {
                        !hasImageError ?
                            <img
                                src={product.thumbnailUrl}
                                alt=""
                                onError={() => setHasImageError(true)}
                                className={styles.imageContainer}
                            /> :
                            <div className={styles.imageFallback}>
                                <Icon className={styles.icon}>no_photography</Icon>
                            </div>
                    }

                    <div className={styles.content}>
                        <div className={styles.name} >
                            {product?.name}
                        </div>

                        <div className={styles.description}>
                            {product?.description}
                        </div>

                        <div className={styles.mobilePrice}>
                            {moneyMaskNumber(product?.price)}
                        </div>
                    </div>

                    {product.compositions && !catalog?.hideCompositions &&
                        <div className={styles.productComposition}>
                            <ProductCompositions
                                productCompositions={product.compositions}
                                onChangeOrderCompositions={onChangeCompositionHandle}
                                disabled={!catalog?.enableShoppingCart}
                            />

                            <div>
                                {
                                    !!product.productGeneralObservation &&
                                    <>
                                        <label className={styles.obsLabel}>Alguma observação:</label>
                                        <textarea
                                            placeholder="Ex.: Sem cebola, sem molho"
                                            rows={5}
                                            max-length={200}
                                            onChange={handleChangeObservation}
                                            className={styles.obsInput}
                                        />
                                    </>
                                }
                            </div>
                        </div>
                    }
                    {
                        !(catalog?.enableShoppingCart === false) &&
                        <footer>
                            <div className={styles.quantityContainer}>
                                <button
                                    type="button"
                                    className={styles.iconButton}
                                    disabled={!(quantity > 1)}
                                    onClick={onClickRemoveQuantityHandle}
                                >
                                    <Icon>remove</Icon>
                                </button>
                                <span className={styles.quantityText}>
                                    {quantity}
                                </span>
                                <button
                                    type="button"
                                    className={styles.iconButton}
                                    onClick={onClickAddQuantityHandle}
                                >
                                    <Icon>add</Icon>
                                </button>
                            </div>
                            <button className={styles.submitButton} disabled={disabledAddProduct} onClick={onClickAddProduct}>
                                <p>Adicionar</p>
                                <span> {moneyMaskNumber(quantity * (product.price + (compositions?.totals.price ?? 0)))}</span>

                            </button>
                        </footer>
                    }
                </div>
            </div>
            :
            <div>
                Carregando Produto
            </div>
    )
}
export default ProductDetail
