import { ToastFullScreen } from 'context/ui/toastFullScreen/ToastFullScreen'
import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import styles from './OnSucessPayment.module.scss'
export interface IOnSucessPaymentProps {
    //propertys
    redirectDisabled?: boolean
}
const OnSucessPayment: FC<IOnSucessPaymentProps> = ({ redirectDisabled }) => {
    const { storeId } = useParams<{ storeId: string }>()
    const { replace } = useHistory();
    const [disabledRedirect, setDisabledRedirect] = useState(redirectDisabled);

    // useEffect(() => {
    //     const query = new URLSearchParams(location.search);
    //     const message = query.get("disabledRedirect");

    // }, [location.search])

    useEffect(() => {

        if (storeId === "1f3d32dd-9f1e-042a-dbb4-09ecb64a1427") { //comercial
            const script = document.createElement('script');
            script.type = "text/javascript"
            script.innerHTML = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '2992812370785665');fbq('track', 'PageView');`
            script.async = true;

            const noscript = document.createElement('noscript');
            noscript.innerHTML = `<img height="1" width="1" style="display:none"src="https://www.facebook.com/tr?id=2992812370785665&ev=PageView&noscript=1"/>`;

            document.head.appendChild(script);
            document.head.appendChild(noscript);
            return () => {
                document.head.removeChild(script);
                document.head.removeChild(noscript);
            }
        } else if (storeId === "24a61c18-f709-5b24-67cc-b9e900095dfe") { //the mexican
            //facebook
            const script = document.createElement('script');
            script.type = "text/javascript"
            script.innerHTML = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '712704685797670');fbq('track', 'PageView');`
            script.async = true;

            const noscript = document.createElement('noscript');
            noscript.innerHTML = `<img height="1" width="1" style="display:none"src="https://www.facebook.com/tr?id=712704685797670&ev=PageView&noscript=1"/>`;

            document.head.appendChild(script);
            document.head.appendChild(noscript);

            //google
            const script2 = document.createElement('script');
            script.type = "text/javascript"
            script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-KQL657X');`
            script.async = true;

            const noscript2 = document.createElement('noscript');
            noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KQL657X"height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

            document.head.appendChild(script2);
            document.head.appendChild(noscript2);


            return () => {
                document.head.removeChild(script);
                document.head.removeChild(noscript);
                document.head.removeChild(script2);
                document.head.removeChild(noscript2);
            }

        } else {
            if (!disabledRedirect) {
                replace("/");
            }
        }
    }, [storeId]);


    return (
        <div id={styles.OnSucessPayment} >
            <div className={styles.container} >
                <ToastFullScreen
                    message="Pagamento realizado com sucesso!"
                    type='success'
                    icon="check"
                    actionButton={!disabledRedirect ? "Continuar" : undefined}
                    onClickActionButton={() => { replace("/") }}
                />
            </div>
        </div>
    )
}
export default OnSucessPayment


