import React, { FC, useCallback, useEffect, useState } from 'react'
import styles from './PixPage.module.scss'
import { Button, Icon, IconButton } from '@material-ui/core'
import { IPixValues } from 'context/payment/PaymentContext';
import { toast } from 'react-toastify';
import { PaymentApi } from 'services/Api/payment/PaymentApi';
import { PaymentStatus } from 'models/payment/PaymentStatus';

export interface IPixPageProps {
    //propertys
    orderId: string,
    data: IPixValues,
    total: number,
    onClose?: () => void,
    onPaymentSuccess: (orderId: string) => void,
}
const PixPage: FC<IPixPageProps> = ({ data, onPaymentSuccess: paymentSuccess, orderId, total, onClose }) => {

    const veryfyPixPayment = useCallback(async () => {
        const response = await PaymentApi().getPaymentStatus(orderId)
        if (response.id === PaymentStatus.PAGAMENTO_REALIZADO) {
            paymentSuccess?.(orderId)
        }
    }, [orderId, paymentSuccess])


    useEffect(() => {
        const timer = setInterval(() => {
            veryfyPixPayment();
        }, 5000)

        return () => {
            clearInterval(timer)
        }
    }, [veryfyPixPayment])

    const onClickCopy = useCallback(() => {
        window.navigator.clipboard.writeText(data.key);
        toast("Codigo copiado com sucesso", { type: "info", delay: 2000 })
    }, [data.key])


    return (
        <div id={styles.PixPage} >
            <h2>PIX</h2>
            {onClose && <IconButton className={styles.closeButton} onClick={onClose}> <Icon>close</Icon></IconButton>}
            <div className={styles.container} >
                {/* <QRCode value={data.qrcodeBase64}></QRCode> */}
                <span>Ler QRCode para efeturar o pagmento </span>
                <h1>{new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                }).format(total)}</h1>
                <img src={`data:image/png;base64,${data.qrCode}`} alt={"QRCode para pagamento"}></img>
                <span>Ou clique em copiar chave</span>
                {/* <div onClick={onClickCopy}>{data.key}</div> */}
                <Button fullWidth variant='contained' color="primary" onClick={onClickCopy}><Icon>content_copy</Icon> copiar chave</Button>
            </div>
        </div>
    )
}
export default PixPage;