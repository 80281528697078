import { IOrderInProgress } from "../../../models/order/aggregates/progress/IOrderInProgress"
import api from "../Api"

export const OrderHistoryApi = () => {

    const getByUser = (userId: string, page: number = 1, pagesize: number = 10) => {
        // const response = api.get<IHistorySectionOrder[]>(`/SuperApp/HistoryOrder/ByUser?page=${page}&pagesize=${pagesize}`)
        return {
            data: []
        };
    }
    const getInProgressOrders = async () => {
        const response = await api.get<IOrderInProgress[]>('/SuperApp/Order/GetOrderInProgressByUser');
        return response
    };


    const getStatusOrders = async (orderId: string) => {
        const response = await api.get<any>(`/Order/${orderId}`);
        return response
    };

    //    await post<any, any>(base + "Order/Cancel", { id: pedidoPOSId });


    return { getByUser, getInProgressOrders, getStatusOrders }
}