
import { IconButton } from '@material-ui/core'
import { Favorite, KeyboardArrowDown, Star, KeyboardArrowUp, LocationOn } from '@material-ui/icons'
import Medal from 'assets/icons/medal.svg'
import Layout from 'components/layout/Layout'
import { Carousel } from 'components/ui/carousel/Carousel'
import Button from 'pages/public/schedule/components/Button/Button'
import React, { useState } from 'react'
import { dayOfWeekToString } from '../formTour/interface/IPlaceScheduleFormSchedule'
import { CardMoreDescription } from './cardMoreDescription/CardMoreDescription'
import styles from './MoreDetails.module.scss'
import { UseMoreDetails } from './UseMoreDetails'

export const MoreDetails = () => {

    const [openDate, setOpenDate] = useState(false);
    const [openValue, setOpenValue] = useState(false);
    const { onClickProductHandle, onClickGoBack, values, serviceId } = UseMoreDetails();

    return (
        <>
            <Layout onClickBack={onClickGoBack} isTopLayout>
                <div className={styles.carousel}>
                    {
                        !!values?.showcaseImages?.length &&
                        <Carousel values={values.showcaseImages.map((item) => item.url)} />
                    }
                </div>
                <div id={styles.MoreDetails}>
                    <div className={styles.container}>
                        {
                            serviceId === '1debb996-0c1c-4942-851f-f990900dc2ae' ?
                                <div className={styles.tourLocation}>
                                    <h3>
                                        {values?.name}
                                    </h3>
                                    <div className={styles.favorite}>
                                        <Star />
                                        <p>Destaque</p>
                                    </div>
                                </div>
                                :

                                serviceId === '8a17f4d2-3aef-4e11-8530-c057abe5f551' ?
                                    <div className={styles.tourLocation}>
                                        <h3>
                                            {values?.name}
                                        </h3>
                                        <div className={styles.favorite}>
                                            <Favorite />
                                            <p>Mais procurado</p>
                                        </div>
                                    </div>
                                    :

                                    serviceId === '72c36fe2-358e-4f73-9ce9-ea185d25ad1e' ?
                                        <div className={styles.tourLocation}>
                                            <h3>
                                                {values?.name}
                                            </h3>
                                            <div className={styles.favorite}>
                                                <img src={Medal} alt="Medalha de mais vendido" />
                                                <p>Mais vendido</p>
                                            </div>
                                        </div>
                                        :
                                        <div className={styles.nameTour}>

                                            <h3>
                                                {values?.name}
                                            </h3>
                                        </div>

                        }
                        <div>
                            <div className={styles.description}>
                                {/* <div className={openViewMore ? styles.showDescription : styles.hideDescription}> */}
                                <div>
                                    {values?.description && values?.description.split('\n').map(str => <p>{str}</p>)}

                                </div>
                                {/* </div> */}
                            </div>
                            {/* <div className={styles.textViewMore} onClick={() => setOpenViewMore((prev) => !prev)}>
                                {
                                    !openViewMore ?
                                        <p>Ver mais</p>
                                        :
                                        <p>Ver menos</p>
                                }
                            </div> */}
                            <div>
                                <div>
                                    {
                                        values?.moreDescriptions?.map((item, index) => (
                                            <CardMoreDescription value={item} key={index} />
                                        ))
                                    }
                                </div>
                                <div className={styles.containerDescription}>
                                    <div onClick={() => setOpenDate((prev) => !prev)}>
                                        <span>Horários do passeio</span>
                                        <IconButton >
                                            {!openDate ? (
                                                <KeyboardArrowDown />
                                            ) : (
                                                <KeyboardArrowUp />
                                            )}
                                        </IconButton>
                                    </div>
                                    <div
                                        className={
                                            openDate ? styles.showCard : styles.hideCard
                                        }
                                    >
                                        {
                                            values?.availableDays?.sort((a, b) => a.dayOfWeek - b.dayOfWeek).map((item) => {

                                                return (
                                                    <p>{dayOfWeekToString(item.dayOfWeek)}: <b>{(item.startHour.substr(0, 5))}h às {(item.endHour.substr(0, 5))}h </b></p>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={styles.containerDescription}>
                                <div onClick={() => setOpenValue((prev) => !prev)}>
                                    <span>Valores</span>
                                    <IconButton>
                                        {!openValue ? (
                                            <KeyboardArrowDown />
                                        ) : (
                                            <KeyboardArrowUp />
                                        )}
                                    </IconButton>
                                </div>
                                <div
                                    className={
                                        openValue ? styles.showCard : styles.hideCard
                                    }
                                >
                                    <div className={styles.containerAvailableDays}>
                                        {
                                            values?.otherValues?.map((item) => (
                                                <p><span>{item.description}</span> - <b>{new Intl.NumberFormat("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                }).format(item.price)}</b></p>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={styles.containerButton}>

                        <Button onClick={onClickProductHandle}>Conferir datas disponíveis</Button>

                    </div>
                </div>
            </Layout>
        </>
    )
}
