import { IPaymentGuestRequest } from "models/paymentGuest/IPaymentGestRequest";
import { IPaymentLinkResponse } from "models/paymentLink/IPaymentLinkResponse"
import { Api } from "../Api"

const PaymentLinkApi = ()=>{

    const api = Api();

    const getPaymentProduct = (paymentLinkId: string) =>{
        return api.get<IPaymentLinkResponse>(`/mepaylink/GetLinkById/${paymentLinkId}`)
    }
    
    const getPaymentGuestSendPay = (paymentGuestRequest: IPaymentGuestRequest) =>{
        return api.post<IPaymentLinkResponse>(`/PayLink`,paymentGuestRequest)
    }

    return{
        getPaymentProduct,
        getPaymentGuestSendPay
    }
}

export default PaymentLinkApi