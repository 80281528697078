import BackButton from 'components/ui/backButton/BackButton'
import React, { FC } from 'react'
import { ISeccionedProduct } from '../productList/interfaces/ISeccionedProduct'
import { TabMenu } from '../tabMenu/_TabMenu'
import styles from './SimpleHeader.module.scss'

export interface ISimpleHeaderProps {
    //propertys
    open: boolean;
    title: string;
    categories: ISeccionedProduct[];
    onClickTab: (index: number) => void;
    position: number;
    onClickBack?: () => void
}
const SimpleHeader: FC<ISimpleHeaderProps> = ({ open, categories, onClickBack, title, onClickTab, position }) => {
    return (
        <div id={styles.SimpleHeader} >
            <div className={open ? styles.containerOpen : styles.containerClose}>
                {onClickBack && <BackButton className={styles.backButton} onClick={onClickBack}></BackButton>}

                <div onClick={() => onClickTab(0)} className={styles.title}><h2>{title}</h2></div>
                <div className={styles.tabWrapper}>
                    <TabMenu onClick={onClickTab} position={position} seccionedProducts={categories} />
                </div>
            </div>
        </div>
    )
}
export default SimpleHeader