export enum EnumAcquirerType {
  MercadoPago = 8,
}

export interface IStoreConfig {
  storeId: string;
  deliveryFee: number;
  allowPaymentOnDelivery: boolean;
  allowOnlinePayment: boolean;
  showDeliveryStatus: boolean;
  allowCatchOnShop: boolean;
  allowDeliveryHome: boolean;
  allowCustomDelivery: boolean;
  allowTableDelivery: boolean;
  customDeliveryDescription: string;
  acquirerType: EnumAcquirerType; //Enum Adiquirente
  isMeepCard: boolean;
  acceptInstallment: boolean;
  installment?: number;
  useMeepDelivery: boolean;
  acceptSlipPayment: boolean;
  disableAutenticateCard: boolean;
  localType?: LocalType
  serviceFee?: number

}

export interface IStoreConfigLocal {
  StoreId: string;
  DeliveryFee: number;
  AllowPaymentOnDelivery: boolean;
  AllowOnlinePayment: boolean;
  ShowDeliveryStatus: boolean;
  AllowCatchOnShop: boolean;
  AllowDeliveryHome: boolean;
  AllowCustomDelivery: boolean;
  CustomDeliveryDescription: string;
  AllowTableDelivery: boolean;
  AcquirerType: EnumAcquirerType; //Enum Adiquirente
  IsMeepCard: boolean;
  AcceptInstallment: boolean;
  Installment?: number;
  UseMeepDelivery: boolean;
  AcceptSlipPayment: boolean;
  DisableAutenticateCard: boolean;
  LocalType?: LocalType.restaurant;
  ServiceFee?: number
}

export enum LocalType {
  restaurant = 1
}