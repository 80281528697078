import { Button } from '@material-ui/core';
import { moneyMaskNumber } from 'cross-cutting/masks/money';
import React, { FC } from 'react'
import styles from './PaymentLinkPage.module.scss'
import { UsePaymentLink } from './_UsePaymentLink';

export interface IPaymentLinkPageProps {
    //propertys
}
const PaymentLinkPage: FC<IPaymentLinkPageProps> = () => {
    const { paymentLink, goToPayment } = UsePaymentLink();

    return (
        <div id={styles.PaymentLinkPage} >
            <div className={styles.container} >
                <div className={styles.decoracaoLeft} />
                <div className={styles.decoracaoRight} />

                {paymentLink &&
                    <div className={styles.content} >
                        <div className={styles.box} >
                            <div className={styles.valor} >
                                <h2>Valor Total</h2>
                                <h1>{moneyMaskNumber(paymentLink.price)}</h1>
                            </div>
                            <div className={styles.nome}>
                                <h3>Produto</h3>
                                <h1>{paymentLink?.product.nome}</h1>
                            </div>
                            <div className={styles.descricao} >
                                <h3>Descrição</h3>
                                {paymentLink?.description}
                            </div>
                            <div className={styles.buttonContainer} >
                                <Button
                                    fullWidth
                                    className={styles.button}
                                    color={"primary"}
                                    variant="contained"
                                    size="large"
                                    onClick={goToPayment}
                                >
                                    PAGAR
                                </Button>
                            </div>
                        </div>
                    </div>
                }
                <div className={styles.footer} >
                    <h3>Soluções de pagamentos by</h3>
                    <img src={require('res/images/paymentLink/meep-footer.png')} width={141} alt="Logo Meep" />
                </div>
            </div>
        </div>
    )
}
export default PaymentLinkPage