import { useUi } from 'context/ui/UIContext';
import { ProductType } from 'models/catalog/enums/ProductType';
import { IOrder } from 'models/order/IOrder';
import { IPaymentLinkResponse } from 'models/paymentLink/IPaymentLinkResponse';
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import SendOrderApi from 'services/Api/order/SendOrderApi';
import PaymentLinkApi from 'services/Api/paymentLink/PaymentLinkApi';
import { v4 } from 'uuid';

export const UsePaymentLink = () => {


    const [paymentLink, setPaymentiLink] = useState<IPaymentLinkResponse>();

    const { showLoading, hideLoading } = useUi();

    const { paymentLinkId } = useParams<{ paymentLinkId: string }>()

    const {push} = useHistory();


    useEffect(() => {
        if (paymentLinkId) {
            const paymentLinkApi = PaymentLinkApi();
            showLoading();
            paymentLinkApi.getPaymentProduct(paymentLinkId).then((response) => {
                setPaymentiLink(response.data);
            }).finally(() => {
                hideLoading();
            })
        }
        return () => {

        }
    }, [paymentLinkId])


    const generateOrderAndRedirect = useCallback(() => {
        const sendOrderApi = SendOrderApi();
        if (paymentLink) {
            const newOrder: IOrder = {
                id: v4(),
                total: paymentLink.price,
                items: [{
                    id: paymentLink.productId,
                    name: paymentLink.product.nome,
                    category: "",
                    price: paymentLink.price,
                    totalPrice: paymentLink.price,
                    description: "",
                    productPrice: paymentLink.price,
                    productId: paymentLink.productId,
                    productType: ProductType.Produto,
                    quantity: 1,
                    imageUrl: ""
                }]
            }

            showLoading();
            sendOrderApi.sendPreOrder(newOrder, paymentLink.localId).then((response)=>{
                push(`/payment?orderId=${response.data.pedidoId}`)
            }).finally(()=>{
                hideLoading();
            });
        }

    }, [paymentLink])

    const goToPayment = useCallback(
        () => {
            if(paymentLink){
                push(`/paymentguest?paymentLinkId=${paymentLink.id}`)
            }
        },
        [paymentLink, push],
    )


    return (
        {
            paymentLink,
            generateOrderAndRedirect,
            goToPayment
        }
    )
}
