import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { ITheme } from "./interfaces/ITheme";
import { ArenaMRVTheme, FoodHallDadoBierTheme, MeepTheme, MineiraoTheme, OutLandTheme, PalmeirasTheme } from 'context/theme/themes/_DefaultTheme';
import { createMuiTheme, Theme, ThemeProvider as ThemeMateriaPovider } from "@material-ui/core";
import Color from 'color'

interface IThemeContext extends ITheme {
  setTheme: React.Dispatch<React.SetStateAction<ITheme>>;
  getColorOn: (color: string) => string;
  detectThemeByUrl: (url?: string) => void
}

const ThemeContext = createContext<IThemeContext>({} as IThemeContext);

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [theme, setTheme] = useState(MeepTheme);

  const [ThemeMaterial, setThemeMaterial] = useState<Theme>(
    createMuiTheme({
      palette: {
        background: {
          default: MeepTheme.backgroundColor,
          paper: MeepTheme.backgroundColor,
        },
        primary: {
          main: MeepTheme.primaryColor,
        },
        secondary: {
          main: MeepTheme.secondaryColor,
        },
      },
      typography: {
        fontFamily: ["Roboto", "Arial"].join(","),
      },
    })
  );

  const getColorOn = useCallback((color: string) => {
    if (Color(color, "rgb").isDark()) {
      return "#fff"
    } else {
      return "#000"
    }
  }, [])



  useEffect(() => {
    theme.backgroundColor && updateCssVar("background", theme.backgroundColor);

    theme.text && updateCssVar("text", getColorOn(theme.backgroundColor));


    if (theme.primaryDark) 
      updateCssVar("primaryDark", theme.primaryDark);
    else
      updateCssVar("primaryDark", theme.primaryColor);

    if (theme.secondaryBackground) 
      updateCssVar("secondaryBackground", theme.secondaryBackground);
    else
      updateCssVar("secondaryBackground", theme.backgroundColor);

    theme.primaryColor && updateCssVar("primary", theme.primaryColor);
    theme.primaryColor && updateCssVar("onPrimary", getColorOn(theme.primaryColor));


    theme.secondaryColor && updateCssVar("secondary", theme.secondaryColor);
    theme.secondaryColor && updateCssVar("onSecondary", getColorOn(theme.secondaryColor));

    theme.accentColor && updateCssVar("accent", theme.accentColor);
    theme.accentColor && updateCssVar("onAccent", getColorOn(theme.accentColor));

    setThemeMaterial(
      createMuiTheme({
        palette: {
          type: theme.type,
          background: {
            default: theme.backgroundColor,
            paper: theme.backgroundColor,
          },
          primary: {
            main: theme.primaryColor,
          },
          secondary: {
            main: theme.secondaryColor,
          },
        },
        typography: {
          fontFamily: ["Roboto", "Arial"].join(","),
        },
      })
    );

    return () => { };
  }, [getColorOn, theme]);


  const detectThemeByUrl = useCallback((url?: string) => {
    if (url) {

      switch (url) {
        case "palmeiras":
          setTheme(PalmeirasTheme);
          break
        case "foodhalldadobier":
          setTheme(FoodHallDadoBierTheme);
          break
        // case 'arenamrv':
        //   setTheme(ArenaMRVTheme);
        //   break
        case 'mineirao':
          setTheme(MineiraoTheme);
          break
        case 'outland':
          setTheme(OutLandTheme);
          break
        case 'arenamrv':
          setTheme(ArenaMRVTheme);
          break    
        default:
          setTheme(MeepTheme);
          break
      }
    }

    return () => {

    }
  }, [setTheme])


  const updateCssVar = (cssVar: string, value: string) => {
    document.documentElement.style.setProperty(`--${cssVar}`, value);
  };

  return (
    <ThemeContext.Provider value={{ ...theme, setTheme, getColorOn, detectThemeByUrl }}>
      <ThemeMateriaPovider theme={ThemeMaterial}>
        <div style={{ minHeight: '100vh', backgroundImage: `url(${theme.backgroundImageUrl})`, backgroundSize: 'contain', backgroundPosition: 'center' }} >
          {children}
        </div>
      </ThemeMateriaPovider>
    </ThemeContext.Provider >
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  return context;
};
