import { Button, Container, Drawer, MenuItem, TextField } from '@material-ui/core';
import { CardItem } from 'components/CardItem/CardItem';
import { moneyMaskNumber } from 'cross-cutting/masks/money';
import PaymentGuestCardForm from 'pages/newCard/cardForm/PaymentGuestCardForm';
import { ResumeView } from 'pages/payment/resume/ResumeView';
import React, { FC, useState } from 'react'
import styles from './PaymentGuestPage.module.scss'
import UsePaymentGuestPage from './UsePaymentGuestPage'

export interface IPaymentGuestPageProps {
    //propertys
}
const PaymentGuestPage: FC<IPaymentGuestPageProps> = () => {

    const { card, setCard, orderDetail, onClickSend, paymentLink, installment, setInstallment } = UsePaymentGuestPage();

    const [openCardForm, setOpenCardForm] = useState(false);

    // const [installments, setInstallments] = useState<IPaymentLinkInstallments[]>([])

    // const calcInstalment = useCallback(() => {
    //     if (paymentLink) {
    //         var newInstalments: IPaymentLinkInstallments[] = []
    //         for (var i = 1; i <= 12; i++) {
    //             newInstalments.push({
    //                 description: `${i}x ${i === 1 ? " A vista" : ""} (R${(paymentLink.price / i).toFixed(2)})`,
    //                 installment: paymentLink?.price / i
    //             })
    //         }
    //         setInstallments(newInstalments);
    //     }
    // }, [paymentLink])

    // useEffect(() => {
    //     calcInstalment();
    // }, [paymentLink])

    return (
        <Container maxWidth="md">
            <div id={styles.PaymentGuestPage} >
                <div className={styles.logo} >
                    <img width={150} src={require("res/images/logos/meep-logo-nome.png")} alt="Logo Meep" />
                </div>
                {
                    orderDetail &&
                    <ResumeView
                        orderDetail={{ ...orderDetail, amount: installment?.price ?? orderDetail.amount }}
                        installment={installment}
                    />
                }
                <div className={styles.container} >
                    <h3>Dados do cartão</h3>
                    {
                        card ?
                            <CardItem
                                textTop={`xxxx.xxxx.xxxx.${card.number.substr(card.number.length - 4, card.number.length)}`}
                                textBottom={card.ownerName}
                                // imageSrc={GetCardTypeImage(card.brandDescription)}
                                onClick={() => setOpenCardForm(true)}
                                // onClickRemove={() => {
                                //     onPressDeleteCard(card);
                                // }}
                                iconName="credit_card"


                            ></CardItem>
                            :
                            <CardItem
                                iconName="credit_card"
                                onClick={() => setOpenCardForm(true)} textTop={"Inserir Cartão"}
                                onClickAdd={() => setOpenCardForm(true)}
                            ></CardItem>

                    }


                    <h3>Parcelas</h3>
                    <TextField className={styles.installmentContainer} fullWidth value={installment} onChange={(ev) => setInstallment(paymentLink?.installments.find(item => item.installment === Number.parseInt(ev.target.value)))} select>
                        {
                            paymentLink?.installments.map((installment) =>
                                <MenuItem value={installment.installment}>{installment.description} - Total {moneyMaskNumber(installment.price)}</MenuItem>
                            )
                        }
                    </TextField>


                    <Button disabled={!card} fullWidth size="large" variant="contained" color="primary" onClick={onClickSend}>Enviar Pagamento</Button>
                </div>
                <Drawer anchor={"bottom"} open={openCardForm} onClose={() => setOpenCardForm(false)}>
                    <PaymentGuestCardForm
                        onSubmit={(card) => { setCard(card); setOpenCardForm(false) }}
                        onClose={() => setOpenCardForm(false)}
                    />
                </Drawer>

            </div>
        </Container >
    )
}
export default PaymentGuestPage