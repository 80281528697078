import { useCart } from 'context/cart/CartContext'
import { ICartItem } from 'context/cart/models/ICart'
import React, { FC, useState } from 'react'
import NumberToMoneyString from 'utils/mask/NumberToMoney'
import styles from './CartItem.module.scss'
import { Icon } from '@material-ui/core'

export interface ICartItemProps {
    cartItem: ICartItem
    totals: number
}

const CartItem: FC<ICartItemProps> = ({ cartItem, totals }) => {
    const { removeCarItem, addCartItem } = useCart();
    const [hasImageError, setHasImageError] = useState(false);

    return (
        <div className={styles.container}>
            {
                !hasImageError ?
                    <img src={cartItem.imageUrl} alt="" className={styles.image} onError={() => setHasImageError(true)} /> :
                    <div className={styles.imageFallback}>
                        <Icon className={styles.icon}>no_photography</Icon>
                    </div>
            }

            <div className={styles.content}>
                <span className={styles.name}>{cartItem.name}</span>
                {cartItem.description && <p className={styles.description}>{cartItem.description}</p>}

                {
                    !!cartItem.orderComposition?.length &&
                    <div className={styles.compositions} >
                        {cartItem.orderComposition?.map((composition) => (
                            <div className={styles.composition} key={composition.id}>
                                <b>{composition.description.toLowerCase()}</b>
                                <div className={styles.compositionNameContent}>
                                    {composition.compositionItems.map(item => (
                                        <span key={item.id}>{item.quantity}x {item.description.toLowerCase()}</span>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                }

                <span className={styles.price}>{NumberToMoneyString(cartItem.totalPrice)}</span>
            </div>

            <div className={styles.totalContainer}>
                <div className={styles.quantityContainer}>
                    <button
                        type="button"
                        className={styles.iconButton}
                        onClick={() => removeCarItem(cartItem.id, 1)}
                    >
                        <Icon>remove</Icon>
                    </button>
                    <span className={styles.quantityText}>
                        {cartItem.quantity}
                    </span>
                    <button
                        type="button"
                        className={styles.iconButton}
                        onClick={() => addCartItem(cartItem.id, 1)}
                    >
                        <Icon>add</Icon>
                    </button>
                </div>
            </div>
        </div>
    )
}
export default CartItem