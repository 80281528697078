
import { Icon, Modal } from '@material-ui/core';
import { useCart } from 'context/cart/CartContext';
import React, { FC, useEffect, useState } from 'react';
import CartPage from '../cartPage/CartPage';
import styles from './CartBar.module.scss';
import './CartBar.css';
import NumberToMoneyString from 'utils/mask/NumberToMoney';

export interface ICartBarProps {
    disabledSales?: boolean
}

const CartBar: FC<ICartBarProps> = () => {
    const { totals, cartItems } = useCart();
    const [visible, setVisible] = useState(false);
    const [mount, setMount] = useState(false);

    const [openCart, setOpenCart] = useState(false);

    // const onEndAnimationHandle = useCallback(() => {
    //     if (mount && !visible) {
    //         setMount(false);
    //     }
    // }, [mount, visible])

    useEffect(() => {
        if (totals?.totalQuantity) {
            setVisible(true);
            setMount(true);
        } else {
            setOpenCart(false)
            setVisible(false)
        }
        return () => {

        }
    }, [totals]);

    const totalCart = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0)

    if (!mount) return <></>;

    return (
        <>
            <button className={styles.cartContainer} onClick={() => setOpenCart(true)}>
                <div>
                    <Icon className={styles.icon}>shopping_cart</Icon>
                    {
                        !!totals?.totalQuantity &&
                        <div className={styles.badge}>
                            <span>{totals.totalQuantity}</span>
                        </div>
                    }
                </div>

                <span className={styles.title}>Carrinho</span>
                
                <span className={styles.price}>
                    {NumberToMoneyString(totals.totalPrice)}
                </span>
            </button>
            <Modal open={openCart} onClose={() => setOpenCart(false)}>
                <CartPage onClickClose={() => setOpenCart(false)} />
            </Modal>
        </>
    )
}
export default CartBar