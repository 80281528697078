export enum CreditCardStatus {
    PENDING = 1,
    WAITING_VALIDATION = 2,
    VALIDATED = 3,
}

//VIEW MODEL
export interface ICardItem {
    id: string;
    finalNumber: string;
    cardColor?: string;
    brandId: number;
    brandDescription: string;
    acquirer: number;
    token: string;
    status: CreditCardStatus;
}

//ADICIONAR MODEL
export interface ICreditCardAddRequest {
    number: string,
    securityCode: string,
    ownerName: string,
    month: string;
    year: string;
    customer?: ICreditCardAddOwnerRequest;
}
export interface ICreditCardAddOwnerRequest {
    name: string;
    cpf: string;
    email: string;
    phoneNumber: string;
    addressId: string;
}

//form/////////
export interface ICreditCardAddFormBase {
    number: string,
    securityCode: string,
    ownerName: string,
    month: string;
    year: string;
}

export interface ICreditCardAddForm extends ICreditCardAddFormBase {
    customer: ICreditCardOwnerForm;
    isOwner?: boolean
}
//form PaymentGuest
export interface ICreditCardOwnerForm extends ICreditCardAddOwnerRequest {

}

//////////////
export interface INewCardPostObject extends ICreditCardAddRequest {
    userId: string
}

//CHECK MODELS
export interface ICheckCard {
    creditCardId: string
    value: number
}
export interface IInitCheckCard {
    creditCardId: string
    cvv: string
}

//DELETE MODELS
export interface ICreditCardItem {
    id: string;
    finalNumber: string;
    cardColor?: string;
    brandId: number;
    brandDescription: string;
    acquirer: number;
    token: string;
    status: CreditCardStatus;
    customer?: ICreditCardAddOwnerRequest;
    type: number;
}



