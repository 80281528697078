import { useAuth } from 'context/auth/AuthContext'
import { useParamsConfig } from 'context/paramsConfig/ParamsConfigContext'
import { useUi } from 'context/ui/UIContext'
import { ISendValidationCode, } from 'models/auth/IValidationCode'
import { IUser, IUserRegisterRequest } from 'models/user/IUser'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import { useLocation, useParams } from 'react-router-dom'
import AuthApi from 'services/Api/auth/AuthApi'
import UserApi from 'services/Api/user/UserApi'
import { EtapasAuth } from './EtapasAuth'


const useAuthPage = (onLogin?: (authValues?: { token?: string, user?: IUser }) => void) => {
    const [user, setUser] = useState<IUser>({} as IUser);
    const [smsValidationId, setSmsValidationId] = useState("");

    const { showLoading, hideLoading, toast } = useUi();
    const { queryParams } = useParamsConfig();
    const { updateToken, updateUser } = useAuth();

    const [etapa, setEtapa] = useState<EtapasAuth>(EtapasAuth.PhoneNumber);

    const history = useHistory();

    let query = new URLSearchParams(useLocation().search);

    const {
        url
    } = useParams<{ url: string }>();

    /**
     * login com usuario e senha - Será atualizado o user com os dados disponiveis no Sistema meep
     * @param username usuario Meep (e-mail) 
     * @param password senha Meep
     */


    const inIframe = () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    const onSucessLogin = useCallback(
        (authValues?: { token?: string, user?: IUser }) => {
            if (onLogin) {
                onLogin(authValues);
            } else {
                if (queryParams?.orderId || queryParams?.accountId) {
                    history.replace(`/payment`);
                } else {
                    const goto = query.get("goto");
                    if (goto) {
                        history.replace(`${goto}`);
                    } else {
                        history.replace(`/`);
                    }
                }
            }
        },
        [history, query, queryParams, onLogin],
    )

    const loginWithUsername = useCallback(
        async (username: string, password: string) => {
            try {
                // showLoading()
                const authApi = AuthApi();
                const response = await authApi.login(username, password, smsValidationId);
                if (response.status === 200) {
                    // if (!onLogin) {
                    updateToken(response.data.access_token);
                    const _userFromLogin: IUser = JSON.parse(response.data.user);
                    const user = {
                        id: _userFromLogin.id ?? "",
                        cpf: _userFromLogin.cpf ?? "",
                        name: _userFromLogin.name ?? "",
                        email: _userFromLogin.email ?? "",
                        phoneNumber: _userFromLogin.phoneNumber ?? "",
                        ddd: _userFromLogin.ddd ?? "",
                    }
                    setUser(user);
                    updateUser(user);
                    // }
                    onSucessLogin({ token: response.data.access_token, user: user });
                } else {
                    toast("Email ou senha não encontrados", "warning");
                }
            } catch (error) {
                console.error("Error login: ", error);
            } finally {
                // hideLoading();
            }
        },
        [smsValidationId, onSucessLogin, updateToken, updateUser, toast]
    );

    const requestValidationCode = useCallback(
        async (data: ISendValidationCode) => {
            try {
                const authApi = AuthApi();
                showLoading();
                await authApi.requestValidationCode(data);
                setUser({ ddd: data.ddd, phoneNumber: data.phoneNumber, ddi: data.ddi } as IUser);
                setEtapa(EtapasAuth.SMSCode);
                if (data.isSecondAttempt) {
                    toast('Código de confirmação enviado!', 'success')
                }
            } catch (error: any) {
                if (error?.response?.status === 400) {
                    toast("Erro", "warning");
                }
            } finally {
                hideLoading();
            }
        },
        [hideLoading, showLoading, toast]
    );



    /**
     * Validar codigo recebido por sms, caso de certo ele ira atualizar o usuario e setar o token alem de redirecionar para proxima pagina
     */
    const validateSmsCode = useCallback(
        async (validationCode: string) => {
            try {

                showLoading();
                const authApi = AuthApi();
                const response = await authApi.validateSmsCode({
                    phoneNumber: user.phoneNumber,
                    ddd: user.ddd,
                    codeVerification: validationCode,
                });
                if (response.status === 200) {
                    setSmsValidationId(response.data.smsValidationId);
                    if (response.data.token) {
                        // if (!onLogin) {
                        updateToken(response.data.token);
                        const user = {
                            id: response.data.userId ?? "",
                            cpf: response.data.cpf ?? "",
                            name: response.data.name ?? "",
                            email: response.data.email ?? "",
                            phoneNumber: response.data.phoneNumber ?? "",
                            ddd: response.data.ddd ?? "",
                        }

                        setUser(user);
                        updateUser(user);
                        // }
                        onSucessLogin({ token: response.data.token, user: user });
                    } else {
                        setEtapa(EtapasAuth.Cpf);
                    }
                }
            } catch (error: any) {
                if (error?.response?.status === 400) {
                    // toast(error.response.data.message, "warning");
                }
            } finally {
                hideLoading();
            }
        },
        [hideLoading, onSucessLogin, showLoading, updateToken, updateUser, user]
    );


    const registerNewUser = useCallback(
        async (data: IUserRegisterRequest) => {
            try {
                showLoading();
                const userApi = UserApi()
                const response = await userApi.register(data);
                if (response?.status === 201) {
                    // if (!onLogin) {
                    updateToken(response.data.Token);
                    const user = {
                        id: response.data.Id ?? "",
                        cpf: response.data.Cpf ?? "",
                        name: response.data.Name ?? "",
                        email: response.data.Email ?? "",
                        phoneNumber: response.data.PhoneNumber ?? "",
                        ddd: response.data.Ddd ?? "",
                    }

                    setUser(user);
                    updateUser(user);
                    // }
                    onSucessLogin({ token: response.data.Token, user: user });
                }
            } catch (error) {
                console.log(error);
            } finally {
                hideLoading();
            }
        },
        [hideLoading, onSucessLogin, showLoading, updateToken, updateUser]
    );



    const requestEmailByCpf = useCallback(
        async (cpf: string) => {
            try {
                setUser({ ...user });
                const userApi = UserApi();
                const response = await userApi.getEmailByCpf(cpf);
                if (response.status === 200) {
                    const email = response.data.email;
                    if (email) {
                        setUser({ ...user, email, cpf });
                        setEtapa(EtapasAuth.Username);
                    } else {
                        setUser({ ...user, email: "" });
                        setEtapa(EtapasAuth.NewUser);
                    }
                } else {
                    setUser({ ...user, cpf });
                    setEtapa(EtapasAuth.NewUser);
                }
            } catch (error) {
                console.log(error);
            }
        },
        [user]
    );



    return (
        {
            loginWithUsername,
            validateSmsCode,
            requestValidationCode,
            registerNewUser,
            requestEmailByCpf,
            user,
            smsValidationId,
            etapa,
            setEtapa
        }
    )
}
export default useAuthPage



