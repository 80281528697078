import { useAuth } from 'context/auth/AuthContext';
import React, { useCallback, useEffect } from 'react'
import { useUi } from './UIContext';
import Api from 'services/Api/Api';

export const UseOnErroApi = () => {
    const { logout } = useAuth();
    const { toast } = useUi();
    const onError = useCallback(
        (error: any) => {
            if (error?.response?.status === 401) {
                toast("Faça login novamente", 'error');
                logout();
                throw error;
            }
            if (error?.response?.data?.message) {
                toast(error?.response?.data?.message, 'error');
                return
            } else if (error?.response?.data?.Message) {
                toast(error?.response?.data?.Message, 'error');
                return
            } else if (error?.response?.data) {
                toast(error?.response?.data, 'error');
                return
            }
            throw error;
        },
        [logout, toast],
    );

    useEffect(() => {
        Api.interceptors.response.use(value => value, onError);
    }, [onError]);
    
    return (
        <></>
    )
}
